import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import LogoImage from 'common/assets/image/appModern/logo-white.png';
import LogoImageAlt from 'common/assets/image/appModern/logo.png';
import usePhantom from '../../../hooks/usePhantom';
import  { ImageWrapper } from './navbar.style'
import { openModal, closeModal } from '@redq/reuse-modal';
import tradingLeaguePopup from 'common/assets/image/samo/coming-soon-trading-league.png';


const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);
const Navbar = () => {
  const { connect, disconnect, isConnected } = usePhantom();
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  const { navMenu } = data.appModernJson.navbar;
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

/*   const handleOnChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    });
  }; */

/*   const handleSearchForm = (event) => {
    event.preventDefault();

    if (state.search !== '') {
      console.log('search data: ', state.search);

      setState({
        ...state,
        search: '',
      });
    } else {
      console.log('Please fill this field.');
    }
  }; */

  const ModalContent = (img) => (
    <ImageWrapper>
      <img
        title="How to Stake on Solana"
        src={img}
        alt='How to Stake'
        frameBorder="0"
      />
    </ImageWrapper>
  );
  
  

    // modal handler
    const handleImgModal = (img) => {
      openModal({
        config: {
          className: 'video-modal',
          disableDragging: true,
          default: {
            width: 'auto',
            height: 'auto',
            x: 0,
            y: 0,
          },
        },
        component: ()=>ModalContent(img),
        componentProps: {},
        closeComponent: CloseModalButton,
        closeOnClickOutside: true,
      });
    };

  const scrollItems = [];

  navMenu.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="App Modern"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="App Modern"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}


          <a 
            className="content" 
            id="a-home-link" 
            title="Home"
            href="/" 
          >Home</a>

          <a 
            className="content" 
            id="trading-league-link" 
            title="Trading League"
            href="/trading-league" 
          >Trading League</a>

          <a
            className="content" 
            id="content-link" 
            title="Research"
            href="https://samodao.substack.com" 
          >Research</a>

          <a 
            className="content" 
            id="what’s-new-link" 
            title="What’s New" 
            href="/what’s-new"
          >Initiatives</a> {/* `What's new` was written here. */}


          <a 
            className="content" 
            id="scholarship-link" 
            title="Scholarship" 
            href="/our-scholarships"
          >Scholarship</a>

          {/* main time */}
          {/* <a 
            className="content" 
            id="alpha-league-link" 
            title="Alpha League"
            href="/alpha-league" 
          >Alpha League</a> */}

{/* don't currently need */}
{/*           <Search className="search" ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input
                type="text"
                value={state.search}
                placeholder="Enter your keyword"
                onChange={handleOnChange}
              />
            </form>
            <Button
              className="text"
              variant="textButton"
              icon={<Icon icon={state.searchToggle ? x : search} />}
              onClick={() => toggleHandler('search')}
            />
          </Search> */}
          {/* end of search */}

          {/* {!isConnected ? <Button onClick={connect} title="Connect"/> : <Button onClick={disconnect} title="Disconnect"/>}           */}
          
          


          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            {navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <AnchorLink
                  href={menu.path}
                  offset={menu.offset}
                  onClick={handleRemoveMenu}
                >
                  {menu.label}
                </AnchorLink>
              </li>
            ))}

            <li>
              <a href="/" title="Home" onClick={handleRemoveMenu}>
                Home
              </a>
            </li>
            <li>
              <a href="/trading-league" title="Trading League" onClick={handleRemoveMenu}>
                Trading League
              </a>
            </li>
            <li>
              <a href="https://samodao.substack.com" title="Research" onClick={handleRemoveMenu}>
                Research
              </a>
            </li>
            <li>
              <a  href="/what’s-new" title="What’s New" onClick={handleRemoveMenu}>
                Initiatives
              </a> {/* `What's new` was written here. */}
            </li>  
            <li>
              <a  href="/our-scholarships" title="Scholarship" onClick={handleRemoveMenu}>
                Scholarship
              </a>
            </li>
            {/* <li>
              <a  href="/alpha-league" title="Alpha League" onClick={handleRemoveMenu}>
                Alpha League
              </a>
            </li> */}
          </Scrollspy>
         
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
