import {
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    Transaction,
  } from "@solana/web3.js";
  import { useEffect, useState } from "react";
import { DAO_CONFIG } from "../config/daoRules";
import { NETWORK } from "../utils/constsants";
import { getTokenAccounts, TokenAccounts } from "../utils/fetchMints";
import { SAMO_MINT_ADDRESS, SAMO_MINT_LIST } from "../utils/mintList";
  
  type DisplayEncoding = "utf8" | "hex";
  type PhantomEvent = "disconnect" | "connect";
  type PhantomRequestMethod =
    | "connect"
    | "disconnect"
    | "signTransaction"
    | "signAllTransactions"
    | "signMessage";
  
  interface ConnectOpts {
    onlyIfTrusted: boolean;
  }
  
  interface PhantomProvider {
    publicKey: PublicKey | null;
    isConnected: boolean | null;
    signTransaction: (transaction: Transaction) => Promise<Transaction>;
    signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
    signMessage: (
      message: Uint8Array | string,
      display?: DisplayEncoding
    ) => Promise<any>;
    connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
    disconnect: () => Promise<void>;
    on: (event: PhantomEvent, handler: (args: any) => void) => void;
    request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
  }
  export interface UsePhantom {
    provider: PhantomProvider;
    balance: number | undefined;
    logs: string[];
    connect: () => Promise<void>;
    disconnect: () => Promise<void>;
    isConnected: PublicKey | null;
  }
  
  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      const anyWindow: any = window;
      const provider = anyWindow.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };
    
  function usePhantom() {
    const connection = new Connection(NETWORK);
  
    const [provider, setProvider] = useState<PhantomProvider>();
    const [balance, setBalance] = useState<number>();
    const [pubKey, setPubKey] = useState<PublicKey | null>(null)
    const [logs, setLogs] = useState<string[]>([]);
    const [isDaoMember,setIsDaoMember] = useState<boolean>(false);
    const addLog = (log: string) => setLogs([...logs, log]);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (provider) {
        return () => {
          provider.disconnect();
        };
      }
      const theProvider = getProvider();
      setProvider(theProvider);
    }, [provider]);
  
    const connect = async () => {
      console.log('running onConnect')

      try {
        const res = await provider.connect();
        addLog(JSON.stringify(res));
        const publicKey = (res.publicKey);
        setPubKey(publicKey);
        connection.getBalance(publicKey).then((bal) => {
            const balance = bal / LAMPORTS_PER_SOL;
            console.log(`Connected to ${publicKey.toString()}. Balance: ${balance}.`)
            setBalance(balance);
        });
        verifyDaoMembership(publicKey).then(res=>setIsDaoMember(res))
        
      } catch (err) {
        console.warn(err);
        addLog("Error: " + JSON.stringify(err));
      }
    };
  
    const disconnect = async () => {
      try {
        const res = await provider.disconnect();
        console.log('Wallet Disconnected.')
        setPubKey(null);
        addLog(JSON.stringify(res));
      } catch (err) {
        console.warn(err);
        addLog("Error: " + JSON.stringify(err));
      }
    };

    const verifyDaoMembership = async (user: PublicKey): Promise<boolean> => {
      console.log('verifying dao membership');
      if (!user) return;
      const tokenAccounts = await getTokenAccounts(user.toString(),connection);
      const samoBalance = getSamoBalance(tokenAccounts);
      const samoNftQty = getSamoNftQty(tokenAccounts);
      const isMember = (samoBalance >= DAO_CONFIG.minimumSamoQty) && (samoNftQty >= DAO_CONFIG.minimumNftQty);
      console.log('Samo Balance: ',samoBalance);
      console.log('Samo NFT qty: ', samoNftQty);
      console.log('IsMember: ', isMember);
      return isMember;
    }

    const getSamoBalance = (holderTokens: TokenAccounts[]):number => {
      const samoAccount = holderTokens.find(tokenAccount=>{
        return tokenAccount.mintAddress === SAMO_MINT_ADDRESS;
      })
      if (!samoAccount) return 0;
      return samoAccount.quantity;
    }

    const getSamoNftQty = (holderTokens: TokenAccounts[]):number => {
      const ownedSamoNfts = holderTokens.filter(tokenAccount => SAMO_MINT_LIST.includes(tokenAccount.mintAddress));
      const samoNftQty = ownedSamoNfts.length;
      return samoNftQty;
    }
  
    return {
      provider,
      balance,
      logs,
      pubKey,
      connect,
      disconnect,
      isConnected: provider && provider.publicKey,
      isDaoMember
    };
  }
  
  export default usePhantom;