import React, { useEffect } from 'react';

const Layout = ({ children }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-3P6P293DVR';

    const initializeGtag = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-3P6P293DVR');
    };

    script.onload = initializeGtag;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {children}
    </div>
  );
};

export default Layout;
