import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export function openUrl(url: string){
    window.open(url, "_blank");
  }

export function formatNumber (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue)).toFixed(0);

}

export const lamportsToSol = (lamports) => {
  return lamports/LAMPORTS_PER_SOL;
}

export const generateTokenText = (number, unit) => {
  try {
      const newValue = formatNumber(number);
      const newName = unit + ': ' +  newValue;
      return newName;
  }
  catch {
      console.error('error generating token name');
  }
}