import { clusterApiUrl } from "@solana/web3.js";

export const NETWORK = process.env.GATSBY_SOLANA_RPC;
export const TOKEN_DETAIL = {
    sol: {
        address: '7iCV5tQ1nNjpxwdcSpCHCM5Sbmhs4oHkFjfZWa7zYCmf',
        label: 'SOL'
    },
    usdc: {
        address: 'ED2M5j7Bc51YnhbWgDiStzadxRBN2XiQDtKW3W2sV7VP',
        label: 'USDC',
        mint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
    },
    samo: {
        address: 'HX68eFni5VyZvRsyupSVR9MxYyV2TrDskKTNy5jbFVSw',
        label: 'SAMO',
        mint: '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU'
    }
}

